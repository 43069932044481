import Slider from './Slider'
import Cards from './Cards'
import Jumbotron from './Jumbotron'
import JumConteiner from './JumConteiner'

const Home = () => {
  return (
    <>
      <Slider />
      <Cards />
      <Jumbotron text={'Північне сяйво'} />
      <JumConteiner />
    </>
  )
}

export default Home
