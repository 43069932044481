import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const ModalNoSettings = (props) => {
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Кнопка не налаштована
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Це демонстраційний сайт, кнопки не налаштоварні!</Modal.Body>
      <Modal.Footer>
        <Button variant='outline-success' onClick={props.onHide}>
          Закрити
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalNoSettings
