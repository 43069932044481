import Carousel from 'react-bootstrap/Carousel'
import images01 from '../images/01.jpg'
import images02 from '../images/02.jpg'
import images03 from '../images/03.jpg'
import images04 from '../images/04.jpg'

function Slider() {
  const sliderElement = [
    {
      img: images01,
      header: 'Mayan ruins in Tikal, Guatemala',
      text: 'Тікаль — найбільше із зруйнованих стародавніх доколумбівських міст майя, розквіт якого припав на 700 рік нашої ери.',
    },
    {
      img: images02,
      header: 'Copper Coast Geopark, Ireland',
      text: 'Геопарк Мідного узбережжя - це визначена територія, що включає ділянку південного узбережжя Ірландії в графстві Вотерфорд.',
    },
    {
      img: images03,
      header: 'Black grouses lekking',
      text: 'Лекінг тетерева - шлюбна поведінка птахів тетеревів. Шлюбний період у тетеревів починається з кінця березня до початку квітня.',
    },
    {
      img: images04,
      header: 'Javan tree frog',
      text: 'Цей персонаж, схожий на Керміта, - яванська деревна жаба, чиї круглі, липкі пальці на ногах допомагають їй маневрувати навколо гілочок і листя.',
    },
  ]

  return (
    <Carousel>
      {sliderElement.map((el) => (
        <Carousel.Item key={el.img}>
          <div
            className='imagesBox'
            style={{ backgroundImage: `url("${el.img}")` }}
          >
            {/* <img className="d-block w-100" src={el.img} alt={el.header} /> */}
          </div>
          <Carousel.Caption>
            <h3>{el.header}</h3>
            <p>{el.text}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default Slider
