import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import ModalLogIn from '../Components/ModalLogIn'
import React from 'react'
import { Link } from 'react-router-dom'

function Navibar() {
  const [modalShow, setModalShow] = useState(false)

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <Navbar
        expanded={expanded}
        bg='dark'
        variant='dark'
        expand='lg'
        fixed='top'
      >
        <Container>
          <Navbar.Brand>
            <Link
              onClick={scrollToTop}
              to='/'
              className='nav-link'
              role='button'
            >
              LOGO
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : 'expanded')}
            aria-controls='basic-navbar-nav'
          />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto' onClick={scrollToTop}>
              <Link
                onClick={() => setExpanded(false)}
                to='/'
                className='nav-link'
                role='button'
              >
                Головна
              </Link>
              <Link
                onClick={() => setExpanded(false)}
                to='about'
                className='nav-link'
                role='button'
              >
                Про нас
              </Link>
              <Link
                onClick={() => setExpanded(false)}
                to='contact'
                className='nav-link'
                role='button'
              >
                Контакти
              </Link>
            </Nav>
            <Stack direction='horizontal' gap={2}>
              <Button variant='success' onClick={() => setModalShow(true)}>
                Увійти
              </Button>
              <Button variant='success' onClick={() => setModalShow(true)}>
                Вийти
              </Button>
            </Stack>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ModalLogIn show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Navibar

// Закривати меню при кліку
// collapseOnSelect не працювала, можливо, так як вже був обробник події

// https://stackoverflow.com/questions/40491483/hide-collapse-navbar-after-link-click-react-redux-react-bootstrap

/**
 ** const [expanded, setExpanded] = useState(false);
Second in the Navbar we add this prop:

** <Navbar expanded={expanded}>
Third, we add an onClick event to the toggle handler that changes the menu visibility status:

** <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
Fourth we add the prop

** <Link to="/" onClick={() => setExpanded(false)}>Menu</Link>
 */
