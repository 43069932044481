import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import images01 from '../images/01.jpg'
import images02 from '../images/02.jpg'
import images03 from '../images/03.jpg'
import images04 from '../images/04.jpg'
import images05 from '../images/05.jpg'
import ModalNoSettings from './ModalNoSettings'
import { useState } from 'react'
// import images06 from '../images/06.jpg'
// import images07 from '../images/07.jpg'

const Cards = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <Container fluid='sm'>
      <Row xs={1} lg={3} className='g-4 m-3'>
        <Col>
          <Card>
            <Card.Img variant='top' src={images01} />
            <Card.Body>
              <Card.Title>Mayan ruins in Tikal</Card.Title>
              <Card.Text>
                Тікаль — найбільше із зруйнованих стародавніх доколумбівських
                міст майя, розквіт якого припав на 700 рік нашої ери
              </Card.Text>
              <Button variant='success' onClick={() => setModalShow(true)}>
                Детальніше
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant='top' src={images02} />
            <Card.Body>
              <Card.Title>Copper Coast Geopark</Card.Title>
              <Card.Text>
                Геопарк Мідного узбережжя - це визначена територія, що включає
                ділянку південного узбережжя Ірландії в графстві Вотерфорд.
              </Card.Text>
              <Button variant='success' onClick={() => setModalShow(true)}>
                Детальніше
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant='top' src={images03} />
            <Card.Body>
              <Card.Title>Black grouses lekking</Card.Title>
              <Card.Text>
                Лекінг тетерева - шлюбна поведінка птахів тетеревів. Шлюбний
                період у тетеревів починається з кінця березня до початку
                квітня.
              </Card.Text>
              <Button variant='success' onClick={() => setModalShow(true)}>
                Детальніше
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant='top' src={images04} />
            <Card.Body>
              <Card.Title>Javan tree frog</Card.Title>
              <Card.Text>
                Хоча жаби можуть жити як в теплих, так і в холодних місцях,
                більшість з них проживають у вологих тропічних лісах, таких як
                цей.
              </Card.Text>
              <Button variant='success' onClick={() => setModalShow(true)}>
                Детальніше
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant='top' src={images05} />
            <Card.Body>
              <Card.Title>Manatees in Crystal River</Card.Title>
              <Card.Text>
                Чи знаєте ви, що Кристал-Рівер в окрузі Цитрус - єдине місце у
                Флориді, де можна купатися з ламантинами?
              </Card.Text>
              <Button variant='success' onClick={() => setModalShow(true)}>
                Детальніше
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ModalNoSettings show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  )
}

export default Cards
