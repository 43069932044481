import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Users from './Components/Users'
import About from './Components/About'
import Root from './Components/Root'
import Home from './Components/Home'
import NotFound from './Components/NotFound'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <NotFound />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: 'about',
          element: <About />,
        },
        {
          path: 'contact',
          element: <Users />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default App
