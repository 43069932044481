import Container from 'react-bootstrap/Container'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LogoIcon from '../images/myLogoWhite.png'

const Wrapper = styled.div`
  background: rgba(33, 37, 41, 1);
  color: white;

  a,
  p {
    color: rgba(255, 255, 255, 0.55);
  }
  a:hover {
    color: rgba(255, 255, 255, 0.9);
  }

  ul {
    border-bottom: 1px solid rgba(255, 255, 255, 0.55) !important;
  }
`

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Wrapper>
      <Container fluid className='p-5'>
        <footer>
          <ul className='nav justify-content-center border-bottom pb-3 mb-3'>
            <li className='nav-link'>
              <Link
                onClick={scrollToTop}
                to='/'
                className='nav-success'
                role='button'
              >
                Головна
              </Link>
            </li>
            <li className='nav-link'>
              <Link
                onClick={scrollToTop}
                to='about'
                className='nav-success'
                role='button'
              >
                Про нас
              </Link>
            </li>
            <li className='nav-link'>
              <Link
                onClick={scrollToTop}
                to='contact'
                className='nav-success'
                role='button'
              >
                Контакти
              </Link>
            </li>
          </ul>
          <p className='text-center'>
            © 2023 Демо сайт - перший сайт для портфоліо!
          </p>
          <p className='text-center'>
            <a href='https://vyhivskyi.com'>
              <img
                src={LogoIcon}
                alt='[ vyhivskyi ]'
                style={{ width: '108px' }}
              />
            </a>
          </p>
        </footer>
      </Container>
    </Wrapper>
  )
}

export default Footer
