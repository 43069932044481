import styled from 'styled-components'
import { Link } from 'react-router-dom'

const WrapperF = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  margin: 0;
  padding: 0;
  height: calc(100vh - 56px);
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  div {
    border: 1px solid green;
    width: 300px;
    box-shadow: 0px 5px 21px 2px #669633;
    background-color: #dcfcba;
    margin-bottom: 56px;
    border-radius: 10px;
    padding: 1em;
  }

  div h1 {
    text-align: center;
    color: green;
    font-size: 3rem;
  }

  div p {
    text-align: center;
  }
`

const NotFound = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <WrapperF>
      <div>
        <h1>Error 404</h1>
        <p>Сторінка не знайдена!</p>
        <p>
          Почніть з <Link onClick={scrollToTop}>головної сторінки -></Link>
        </p>
      </div>
    </WrapperF>
  )
}

export default NotFound
