// import Home from './Home'
import Navibar from './Navibar'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'

const Root = () => {
  return (
    <>
      <Navibar />
      <Outlet />
      <Footer />
    </> 
  )
}

export default Root
