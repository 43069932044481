import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

const ModalLogIn = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Увійти</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Адреса електронної пошти</Form.Label>
            <Form.Control type="email" placeholder="Адреса електронної пошти" />
            <Form.Text className="text-muted">
              Ми ніколи нікому не передамо вашу електронну адресу.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Пароль</Form.Label>
            <Form.Control type="password" placeholder="Пароль" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Запам'яти мене" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" type="submit">
          Надіслати
        </Button>
        <Button variant="outline-success" onClick={props.onHide}>
          Закрити
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalLogIn
