import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import images08 from '../images/08.jpg'
import styled from 'styled-components'

const Wrapper = styled.section`
  background: url(${images08}) fixed no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
`

function Jumbotron({ text }) {
  return (
    <Wrapper>
      <Container fluid className="p-5">
        <Row>
          <Col>
            <h1 className="display-5 fw-bold">{text}</h1>
            <p className="col-md-8 fs-4">
              Ми тремтимо цілий день, чи буде це щось з північним сяйвом цієї
              ночі, тому що звичайні густі хмари постійно проходять над нами.
              Вузька арка тягнеться поперек небосхилу і частково відбивається на
              поверхні озера. Ми випробовуємо щастя на переправі через річку
              Камерон і вміємо захоплювати красиві, але вузькі смуги з
              відображенням.
            </p>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default Jumbotron
