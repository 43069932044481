import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import images04 from '../images/04.jpg'
import ModalNoSettings from './ModalNoSettings'
import { useState } from 'react'

function JumConteiner() {
  const [modalShow, setModalShow] = useState(false)

  return (
    <Container className='p-5'>
      <Row>
        <Col md={6}>
          <img
            src={images04}
            width={'100%'}
            alt='Using a series of utilities'
            style={{ borderRadius: '7px' }}
          />
        </Col>
        <Col md={6} className='p-3'>
          <h2>Javan tree frog</h2>
          <p>
            Хоча жаби можуть жити як в теплих, так і в холодних місцях,
            більшість з них проживають у вологих тропічних лісах, таких як цей.
            Шкіра дорсально гладка і вентрально слабо ареолатна. Руки короткі і
            стрункі, тоді як задні кінцівки довгі і стрункі. Пальці рук і ніг
            несуть диски і мають деяку павутину (тим більше у самки). У
            збережених екземплярів спинний колір землі коричневий, коричневий
            або сірий. Самці мають кілька чорних цяток.
          </p>
          <button
            className='btn btn-outline-secondary'
            type='button'
            onClick={() => setModalShow(true)}
          >
            Детальніше
          </button>
        </Col>
      </Row>
      <ModalNoSettings show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  )
}

export default JumConteiner
