import Accordion from 'react-bootstrap/Accordion'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { LinkContainer } from 'react-router-bootstrap'
import Jumbotron from './Jumbotron'
import Input from './Input'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button } from 'react-bootstrap'
import { useState } from 'react'
import ModalNoSettings from './ModalNoSettings'

const About = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <Container fluid='sm' className='p-5'>
        <Breadcrumb>
          <LinkContainer to='/'>
            <Breadcrumb.Item>Головна</Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to='/about' active>
            <Breadcrumb.Item>Про нас</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
      </Container>

      <Jumbotron text={'Про нас'} />

      <Container fluid='sm' className='p-5'>
        <Row className='justify-content-md-center'>
          <Col md={8}>
            <h1>Класифікація</h1>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Класифікація</Accordion.Header>
                <Accordion.Body>
                  <p>
                    До природничих наук зараховують : астрономію — науку про
                    Всесвіт, фізику — науку про склад і структуру матерії, а
                    також про основні явища в неживій природі, хімію — науку про
                    будову й перетворення речовин, біологію та екологію — науки
                    про живу природу, науки про Землю (або Землезнавство — це
                    термін для дисциплін із вивчення планети Земля) — географію,
                    геофізику й геологію, медицину — науку про людське тіло та
                    його хвороби.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>Методи</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Основою наукового методу є спостереження, а в активнішій
                    формі — експеримент.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>Історія природознавства</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Через те, що існування людства історичне, тобто
                    еволюціонувало від простіших станів до більш складних і
                    удосконалених, то й наука пройшла такий самий шлях еволюції.
                    Існує декілька точок зору про час виникнення науки: як
                    доведельний вид знання, відмінний від міфологічного
                    мислення, наука виникла в Стародавній Греції в V столітті до
                    н. е. наука виникла в період пізньої середньовічної
                    культури, коли було розпізнане велике значення дослідного
                    знання в творчості таких діячів церкви в Англії, як Роберт
                    Гросетест, Роджер Бекон. Найпоширеніша точка зору, що наука
                    виникла в XVI-XVII століттях, коли з'явилися праці Йогана
                    Кеплера, Хрістіана Гюйгенса, Галілео Галілея, Ісаака Ньютона
                    та інших вчених. Ознаками науки виступають: побудова
                    математичних моделей об'єктів, емпіричні результати
                    дослідного рівня.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      <Container className='p-5'>
        <Row className='justify-content-md-center'>
          <Col md={8}>
            <h2>Становлення науки</h2>
            <ListGroup>
              <ListGroup.Item>
                Німецький філософ Карл Ясперс говорить про 2 етапи становлення
                науки:
              </ListGroup.Item>
              <ListGroup.Item>
                Становлення логічної і методичної науки у Греції і паралельно
                задатки наукового пізнання світу у Китаї та Індії.
              </ListGroup.Item>
              <ListGroup.Item>
                Виникнення сучасної науки, що виростає з кінця середньовіччя,
                рішуче утверджується з XVIII столітті, розгортається у всю свою
                ширину з XIX століття.
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>

      <Container className='p-5'>
        <Row className='justify-content-md-center'>
          <Col md={8}>
            <h2>Зв'яжіться з нами</h2>
            <Input />
            <Container
              style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
            >
              <Button variant='success' onClick={() => setModalShow(true)}>
                Надіслати
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>

      <ModalNoSettings show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default About
