import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { LinkContainer } from 'react-router-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Input from './Input'
import { Button } from 'react-bootstrap'
import ModalNoSettings from './ModalNoSettings'
import { useState } from 'react'
import Jumbotron from './Jumbotron'

const Users = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <Container fluid='sm' className='p-5'>
        <Breadcrumb>
          <LinkContainer to='/'>
            <Breadcrumb.Item>Головна</Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to='/users' active>
            <Breadcrumb.Item>Контакти</Breadcrumb.Item>
          </LinkContainer>
        </Breadcrumb>
      </Container>

      <Jumbotron text={'Контакти'} />

      <Container className='p-5'>
        <Row className='justify-content-md-center'>
          <Col md={8}>
            <h2>Зв'яжіться з нами</h2>
            <Input />
            <Container
              style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
            >
              <Button variant='success' onClick={() => setModalShow(true)}>
                Надіслати
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>

      <ModalNoSettings show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Users
